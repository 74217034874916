import React, { useContext, useState, useEffect } from "react";
import { GameDataContext } from "../Context/GameDataContext";
import EarningsGrid from "./EarningsGrid";
import "./EarningsSection.css";

const EarningsSection = () => {
  const { rendonTotalGamesAsAngel, takenFromAngels, wagePerSecond2024 } =
    useContext(GameDataContext);

  const [newTakenFromAngels, setNewTakenFromAngels] = useState(takenFromAngels);
  const [dollarsPerHit, setDollarsPerHit] = useState(0);
  const [dollarsPerAB, setDollarsPerAB] = useState(0);
  const [dollarsPerHR, setDollarsPerHR] = useState(0);
  const [dollarsPerRBI, setDollarsPerRBI] = useState(0);
  const [dollarsPerRun, setDollarsPerRun] = useState(0);
  const [dollarsPerBWAR, setDollarsPerBWAR] = useState(0);
  const [dollarsPerFWAR, setDollarsPerFWAR] = useState(0);
  const [earningsPerGame, setEarningsPerGame] = useState(0);

  const hits = 199;
  const atBats = 795;
  const hr = 22;
  const rbi = 114;
  const r = 100;
  const bwar = 3.5;
  const fwar = 3.9;

  useEffect(() => {
    const interval = setInterval(() => {
      setNewTakenFromAngels((prev) => prev + wagePerSecond2024);
    }, 1000);

    return () => clearInterval(interval);
  }, [wagePerSecond2024]);

  useEffect(() => {
    setDollarsPerHit(newTakenFromAngels / hits);
    setDollarsPerAB(newTakenFromAngels / atBats);
    setDollarsPerHR(newTakenFromAngels / hr);
    setDollarsPerRBI(newTakenFromAngels / rbi);
    setDollarsPerRun(newTakenFromAngels / r);
    setDollarsPerBWAR(newTakenFromAngels / bwar);
    setDollarsPerFWAR(newTakenFromAngels / fwar);
    setEarningsPerGame(newTakenFromAngels / rendonTotalGamesAsAngel);
  }, [newTakenFromAngels, rendonTotalGamesAsAngel]);

  const earningsData = [
    { amount: dollarsPerHit, unit: "H", statValue: hits, bottomUnit: "hits" },
    {
      amount: dollarsPerAB,
      unit: "AB",
      statValue: atBats,
      bottomUnit: "at bats",
    },
    { amount: dollarsPerHR, unit: "HR", statValue: hr, bottomUnit: "HR" },
    { amount: dollarsPerRBI, unit: "RBI", statValue: rbi, bottomUnit: "RBI" },
    { amount: dollarsPerRun, unit: "R", statValue: r, bottomUnit: "runs" },
    {
      amount: dollarsPerBWAR,
      unit: "bWAR",
      statValue: 3.5,
      bottomUnit: "bWAR",
    },
    {
      amount: dollarsPerFWAR,
      unit: "fWAR",
      statValue: 3.9,
      bottomUnit: "fWAR",
    },
    {
      amount: earningsPerGame,
      unit: "game played",
      statValue: 199,
      bottomUnit: "games",
    },
  ];

  return (
    <div className="earnings-section">
      <div className="earnings-container">
        <h2 className="earnings-title">
          As an Angel, Anthony Rendon has "Earned"
        </h2>
        <EarningsGrid earningsData={earningsData} />
      </div>
    </div>
  );
};

export default EarningsSection;
