import React, { useContext, useEffect, useState } from "react";
import { GameDataContext } from "../Context/GameDataContext";
import "./SalaryProgress.css";

const formatCurrency = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const SalaryProgress = () => {
  const { takenFromAngels, totalContractValue, earnedPerSecond } =
    useContext(GameDataContext);

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [newTakenFromAngels, setNewTakenFromAngels] = useState(
    Number(takenFromAngels)
  );

  useEffect(() => {
    setProgressPercentage((newTakenFromAngels / totalContractValue) * 100);
  }, [newTakenFromAngels, totalContractValue]);

  useEffect(() => {
    // console.log("earnedPerSecond:", earnedPerSecond); // Debugging log

    const interval = setInterval(() => {
      setNewTakenFromAngels((prevTaken) => {
        const newTaken = prevTaken + earnedPerSecond;
        // console.log("newTaken:", newTaken); // Debugging log
        return newTaken;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [earnedPerSecond]);

  const currentRemainingSalary = totalContractValue - newTakenFromAngels;

  // Debugging logs
  // console.log("currentEarnings:", currentEarnings);
  // console.log("newTakenFromAngels:", newTakenFromAngels);
  // console.log("currentRemainingSalary:", currentRemainingSalary);

  return (
    <div className="salary-progress-container">
      <div className="salary-progress-card">
        <div className="salary-progress-header">
          <div className="taken-label">Stolen From Angels:</div>
          <div className="taken-amount">
            ${formatCurrency(newTakenFromAngels.toFixed(0))}
          </div>
        </div>
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>

        <hr className="divider" />
        <div className="salary-progress-footer">
          <div className="remaining-label">Remaining:</div>
          <div className="remaining-amount">
            ${formatCurrency(currentRemainingSalary.toFixed(0))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryProgress;
