import React from "react";
import GamesMissed from "./GamesMissed";
import GamesPlayed from "./GamesPlayed";
import "./GamesSummary.css";

const GamesSummary = () => {
  return (
    <div className="games-summary-container">
      <GamesMissed />
      <GamesPlayed />
    </div>
  );
};

export default GamesSummary;
