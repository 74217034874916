import React, { useState, useEffect, useContext } from "react";
import "./HeadsUpAlert.css";
import upChart from "../upChart.png"; // Ensure the correct path to the image

// Assuming you have a context that provides the earnings per second
import { GameDataContext } from "../Context/GameDataContext";

const RendonEarningsAlert = () => {
  const { earnedPerSecond } = useContext(GameDataContext);
  const [earnedSinceOnSite, setEarnedSinceOnSite] = useState(0);

  useEffect(() => {
    if (typeof earnedPerSecond === "number" && !isNaN(earnedPerSecond)) {
      const interval = setInterval(() => {
        setEarnedSinceOnSite((prev) => prev + earnedPerSecond);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      console.error("Earnings per second is not a number");
      console.log(earnedPerSecond);
    }
  }, [earnedPerSecond]);

  return (
    <div className="alert">
      <img src={upChart} alt="Up Chart" className="icon" />
      <div className="text-container" style={{ marginLeft: "18px" }}>
        <p className="headline">Heads up!</p>
        <p className="earnings">
          Rendon has earned <strong>${earnedSinceOnSite.toFixed(2)}</strong>{" "}
          while you have been on this site
        </p>
      </div>
    </div>
  );
};

export default RendonEarningsAlert;
