import React from "react";
import "./PerAmountBox.css";

const formatCurrency = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function PerAmountBox({ amount, unit, statValue, bottomUnit }) {
  return (
    <div className="per-amount-container">
      <span className="per-value-dollars">
        ${formatCurrency(amount.toFixed(0))}
      </span>
      <span className="per-hit-subtext">per {unit}</span>
      <div className="per-value-stat-separator"></div>
      {statValue && (
        <span className="per-value-stat">
          {statValue} {bottomUnit}
        </span>
      )}
    </div>
  );
}
