import React, { useContext } from "react";
import { GameDataContext } from "../Context/GameDataContext";
import "./GamesMissed.css";

const GamesMissed = () => {
  const { rendonTotalGamesAsAngel, gamesAngelsPlayedInRendonEra } =
    useContext(GameDataContext);
  const missedGames = gamesAngelsPlayedInRendonEra - rendonTotalGamesAsAngel;

  return (
    <div className="games-missed-container">
      <div className="games-missed-card">
        <h1>Games Missed</h1>
        <p className="games-missed-count">{missedGames}</p>
        <p className="games-missed-subtitle">AND COUNTING!</p>
      </div>
    </div>
  );
};

export default GamesMissed;
