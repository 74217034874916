import React, { useContext, useEffect, useState } from "react";
import { GameDataContext } from "../../Context/GameDataContext";
import "./DidNotPlayEquivalent.css";

const formatCurrency = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function DidNotPlayEquivalent() {
  const { amountFromMissed: initialAmountFromMissed, wagePerSecond2024 } =
    useContext(GameDataContext);
  const [amountFromMissed, setAmountFromMissed] = useState(
    initialAmountFromMissed
  );

  const ohtaniEquivalent = React.useMemo(
    () => amountFromMissed / 28216944,
    [amountFromMissed]
  );

  const aaronJudgeEquivalent = React.useMemo(
    () => amountFromMissed / 40000000,
    [amountFromMissed]
  );

  const bryceHarperEquivalent = React.useMemo(
    () => amountFromMissed / 27538462,
    [amountFromMissed]
  );

  const mookieBettsEquivalent = React.useMemo(
    () => amountFromMissed / 30416667,
    [amountFromMissed]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setAmountFromMissed((prev) => prev + wagePerSecond2024);
    }, 1000);
    return () => clearInterval(interval);
  }, [wagePerSecond2024]);

  return (
    <div className="equivalent-seasons-container">
      <div className="amount-box">
        <span className="amount">
          ${formatCurrency(amountFromMissed.toFixed(0))}
        </span>
        <div className="amount-description">
          <span className="text-bold">in games</span>
          <span className="text-normal">he did not play</span>
        </div>
      </div>
      <div className="separator"></div>
      <div className="equivalent-box">
        <span className="equivalent-title">That's equivalent to</span>
        <div className="equivalent-seasons">
          <ComparisonBox
            number={ohtaniEquivalent.toFixed(2)}
            description="Shohei Ohtani"
          />
          <ComparisonBox
            number={aaronJudgeEquivalent.toFixed(2)}
            description="Aaron Judge"
          />
          <ComparisonBox
            number={bryceHarperEquivalent.toFixed(2)}
            description="Bryce Harper"
          />
          <ComparisonBox
            number={mookieBettsEquivalent.toFixed(2)}
            description="Mookie Betts"
          />
        </div>
      </div>
      {/* <div className="arrows">
        <div className="arrow left-arrow"></div>
        <div className="arrow right-arrow"></div>
      </div> */}
    </div>
  );
}

const ComparisonBox = ({ number, description }) => (
  <div className="season-box">
    <span className="season-number">{number}</span>
    <div>
      <span style={{ fontWeight: 400, fontSize: 18 }}>Seasons of </span>
      <br />
      <span style={{ fontWeight: 700, fontSize: 18 }}>{description}</span>
    </div>
  </div>
);
