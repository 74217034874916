import React from "react";
import "./CreatedBy.css";

const CreatedBy = () => {
  return (
    <div className="created-by-container">
      <span className="created-by-text">Created by</span>
      <a
        href="https://twitter.com/VinRBI"
        className="creator-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>𝕏</span>
        &nbsp;VinRBI
      </a>
    </div>
  );
};

export default CreatedBy;
