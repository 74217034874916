import React, { useContext } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./GamesPlayed.css";
import { GameDataContext } from "../Context/GameDataContext";

const GamesPlayed = () => {
  const { rendonTotalGamesAsAngel, gamesAngelsPlayedInRendonEra } =
    useContext(GameDataContext);

  const percentage =
    (rendonTotalGamesAsAngel / gamesAngelsPlayedInRendonEra) * 100;

  return (
    <div className="games-played-container">
      <div className="games-played-card">
        <h1>Games Played</h1>
        <div className="progress-container">
          <CircularProgressbar
            value={percentage}
            text={`${Math.round(percentage)}%`}
            styles={buildStyles({
              textColor: "black",
              pathColor: "var(--angels-red)",
              trailColor: "var(--system-fill-color)",
              textSize: 28,
              textWeight: 500,
            })}
          />
        </div>
        <div className="games-info-container">
          <div className="info-item">
            <span className="dot red"></span>
            <span>Angels: {gamesAngelsPlayedInRendonEra}</span>
          </div>
          <div className="info-item">
            <span className="dot green"></span>
            <span>Rendon: {rendonTotalGamesAsAngel}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesPlayed;
