import React from "react";
import "./Header.css";
import CreatedBy from "./CreatedBy";

const Header = () => {
  return (
    <div className="header-wrapper">
      <header className="header-container">
        <h1 className="title">Anthony Rendon Theft Tracker</h1>
        <div className="live-indicator">
          <span className="live-dot"></span>
          <span className="live-text">Live</span>
        </div>
      </header>
      <CreatedBy />
    </div>
  );
};

export default Header;
