import React from "react";
import PerAmountBox from "./PerAmountBox";
import "./EarningsSection.css";
import "./EarningsGrid.css";

const EarningsGrid = ({ earningsData }) => {
  return (
    <div className="earnings-grid">
      {earningsData.map((data, index) => (
        <PerAmountBox
          key={index}
          amount={data.amount}
          unit={data.unit}
          statValue={data.statValue}
          bottomUnit={data.bottomUnit}
        />
      ))}
    </div>
  );
};

export default EarningsGrid;
